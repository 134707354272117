import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Bio from "../components/bio"
import BlogPostMeta from "../components/blogPostMeta"
import Layout from "../components/blogLayout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

import '../styles/blog.css'
import ArticlesPager from "../components/articlesPager"
import BackToOverviewButton from "../components/backToOverviewButton"
import PagesNav from "../components/pagesNav"
import TOC from "../components/tableOfContent"

import cardImage from "../../content/assets/og-card.png"

// TODO categories, TODO header in layout

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next, allPages } = this.props.pageContext

    const blogPath = `${__PATH_PREFIX__}/blog`

    return (
      <Layout location={this.props.location} title={siteTitle} pageType={"blog-post"}
        contentMaxWidth={rhythm(24)}
        // leftAside={<PagesNav pages={allPages} thisPage={post} pathPrefix={blogPath} title={"All posts"} />}
        // rightAside={<TOC tableOfContents={post.tableOfContents} inline={false} />}
        below={<ArticlesPager pathPrefix={blogPath} previous={previous} next={next} />} >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={post.frontmatter.hero_image ? `${__PATH_PREFIX__}/${post.frontmatter.hero_image}` : cardImage}
        />
        <BackToOverviewButton to={blogPath} style={{marginBottom: 14}} /> 
        <h1 class="blog-post-title">{post.frontmatter.title}</h1>
        <BlogPostMeta date={post.frontmatter.date} />
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <div class="bio-wrapper">
          <Bio />
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      tableOfContents
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        hero_image
      }
    }
  }
`
